import { convertToReadableFormat } from './formatters';
import { initGrid } from './initGrid';

// build array of selected locations when a row is selected
export function locationRowSelected() {
  const companyLocationArray = window.buildingGrid.getSelectedNodes().map(node => node.data.company_location_id);
  $("#remove-locations").prop("disabled", companyLocationArray.length === 0);
}

// build array of selected units when a row is selected
export function unitRowSelected() {
    const companyLocationUnitArray = window.unitGrid.getSelectedNodes().map(node => node.data.company_location_unit_id);
    $("#remove-units").prop("disabled", companyLocationUnitArray.length === 0);
}

export function removeCompanyLocations(companyLocationArray, companyId) {
  console.log(companyLocationArray);
  $.ajax({
    url: `/companies/${companyId}/bulk-update`,
    dataType: "json",
    method: "POST",
    data: { company: { company_location_ids: companyLocationArray } },
  }).done(function(response) {
    toastr.success("Removed");
    location.reload();
  }).fail(function(error) {
    console.error("Error deleting the value:", error);
  });
}

export function removeCompanyLocationUnits(companyLocationUnitArray, companyId) {
    console.log(companyLocationUnitArray);
    $.ajax({
      url: `/companies/${companyId}/bulk-update`,
      dataType: "json",
      method: "POST",
      data: { company: { company_location_unit_ids: companyLocationUnitArray } },
    }).done(function(response) {
      toastr.success("Removed");
      location.reload();
    }).fail(function(error) {
      console.error("Error deleting the value:", error);
    });
  }



export function updateValue(path, column, value, model, method, reload = false) {


  const companyId = $('#gridContent').data("companyid");
  const hash = {};
  const data = {};
  if (model === "unit" || model === "location" || model === "company_location") {
    hash[column] = value;
    hash["company_id"] = `${companyId}`;
    data[model] = hash;
  } else if (model === "feature") {
    hash["features"] = {};
    hash["features"][column] = value;
    data["location"] = hash;
  } else if (model === "unit_feature") {
    hash["features"] = {};
    hash["features"][column] = value;
    data["unit"] = hash;
    data["unit"]["company_id"] = `${companyId}`;
  }

  $.ajax({
    url: path,
    method: method,
    dataType: "json",
    data: data,
  }).done(function(response) {
    toastr.success(`${convertToReadableFormat(column)} Updated`);
    if (reload) {
      initGrid(companyId);
    }
  }).fail(function(error) {
    console.error("Error updating the value:", error);
  });
}

export function calculateMonthlyValues(event) {

  const { column, data, node } = event;

  const calculations = [
    {
      columns: ["rate", "opex", "electric_costs", "square_footage"],
      costKey: "monthly_cost",
      calculate: () => {
        const squareFootage = parseFloat(data.square_footage);
        const rate = parseFloat(data.rate);
        const opex = parseFloat(data.opex) || 0;
        const electricCosts = parseFloat(data.electric_costs) || 0;
        if (isNaN(squareFootage) || isNaN(rate)) {
          return null;
        }
        const annualCost = squareFootage * (rate + opex + electricCosts);
        const divisor = (data.rate_frequency === "per month") ? 1 : 12;
        return (annualCost / divisor).toFixed(2);
      },
    },
    {
      columns: ["high_rate", "opex", "electric_costs", "high_square_footage"],
      costKey: "high_monthly_cost",
      calculate: () => {
        const highSquareFootage = parseFloat(data.high_square_footage);
        const highRate = parseFloat(data.high_rate);
        const opex = parseFloat(data.opex) || 0;
        const electricCosts = parseFloat(data.electric_costs) || 0;
        if (isNaN(highSquareFootage) || isNaN(highRate)) {
          return null;
        }
        const annualCost = highSquareFootage * (highRate + opex + electricCosts);
        const divisor = (data.rate_frequency === "per month") ? 1 : 12;
        return (annualCost / divisor).toFixed(2);
      },
    },
  ];

  calculations.forEach(({ columns, costKey, calculate }) => {
    if (columns.includes(column.colId)) {
      const cost = calculate();
      if (cost !== null) {
        const formattedCost = parseFloat(cost);
        node.setDataValue(costKey, formattedCost);
        updateValue(`/units/${data.unit_id}`, costKey, formattedCost, "unit", "PATCH");
      } else {
        node.setDataValue(costKey, null);
        updateValue(`/units/${data.unit_id}`, costKey, null, "unit", "PATCH");
      }
    }
  });
  
}
