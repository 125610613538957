import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["map", "list", "mapButton", "listButton"];

  initialize() {
    // super.initialize()
    // this.colorValue = this.element.dataset.colorPickerColorValue
  }

  connect() {
    // this.colorValueChanged()
  }

  toggle(e) {
    e.preventDefault();
    console.log(e)
    if (this.mapTarget.classList.value.indexOf('d-none') > -1) {
        this.mapTarget.classList.remove('d-none');
        this.listTarget.classList.add('d-none');
        this.mapButtonTarget.classList.add('d-none');
        this.listButtonTarget.classList.remove('d-none');
    } else {
        this.mapTarget.classList.add('d-none');
        this.listTarget.classList.remove('d-none');
        this.mapButtonTarget.classList.remove('d-none');
        this.listButtonTarget.classList.add('d-none');
    }
    
  }

  // colorValueChanged() {
  //   this.pickerTarget.value = this.colorValue
  //   this.fieldTarget.value = this.colorValue
  // }
}
