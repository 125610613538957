
export function currencyFormatter(params) {
    if (params.value === undefined || params.value === null) {
      return "N/A";
    } else {
      return "$" + params.value.toLocaleString();
    }
  }
  
  export function defaultFormatter(params) {
    const defaultValues = {
      rate_frequency: "per year",
      default: "N/A"
    };
    if (params.value === undefined || params.value === null) {
      return defaultValues[params.column.colId] || defaultValues.default;
    }
    return params.value;
  }
  
  export function delimiterFormatter(params) {
    if (params.value === undefined || params.value === null) {
      return "N/A";
    } else {
      return params.value.toLocaleString();
    }
  }
  
  export function convertToReadableFormat(snakeCaseString) {
    return snakeCaseString
      .replace(/_id$/, '') // Remove trailing _id if present
      .split('_') // Split by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words with spaces
  }

  export function photoCellRenderer(params) {
  
    // Create a container div
    var container = document.createElement('div');
    container.className = 'photo-cell d-flex';
    
    
    if (params.value || params.value.length > 0) {
      // Create a div to hold the photos
      var photosContainer = document.createElement('div');
      photosContainer.className = 'photos-container mr-10px';
    
      // Iterate through the photo URLs and create img elements
      params.value.forEach(function(photoUrl, index) {
        if (index == 0){
          var img = document.createElement('img');
          img.src = photoUrl;
          img.style.width = '20px'; // Set width to 20px
          img.style.height = '20px'; // Set height to 20px
          photosContainer.appendChild(img);
        }
      });
    }
  
    container.appendChild(photosContainer);
    
    if (params.data.path.includes('units')){
      var link = document.createElement('p');
      link.innerText = `${params.value.length} Photo(s)`;
      container.appendChild(link);  
    } else {
      var link = document.createElement('a');
      link.innerText = `${params.value.length} Photo(s)`;
      link.href = `${params.data.path}/edit/photos`; // Adjust the URL as needed
      link.className = 'color-blue';
      container.appendChild(link);  
    }
    return container;
  }

  export function flyerCellRenderer(params) {
  
    // Create a container div
    var container = document.createElement('div');
    container.className = 'photo-cell d-flex';
    
    // Create a link to navigate to a photos page
    var link = document.createElement('a');
    link.innerText = `${params.value} Flyer(s)`;
    link.href = `${params.data.path}/edit/info`; // Adjust the URL as needed
    link.className = 'color-blue';
    container.appendChild(link);
  
    return container;
  }

  export function buildingNameRenderer(params) {
  
    // Create a container div
    var container = document.createElement('div');
    container.className = 'photo-cell d-flex';
    
    // Create a link to navigate to a photos page
    var link = document.createElement('a');
    link.innerText = `${params.value}`;
    link.href = `${params.data.path.replace(/edit\/units/g, "")}`; // Adjust the URL as needed
    link.className = 'color-blue';
    container.appendChild(link);
  
    return container;
  }
  
  