import { loadLocations } from './loadLocations';
import { loadUnits } from './loadUnits';

export function initGrid(companyId) {
  $.ajax({
    url: `/companies/${companyId}.json`,
  }).done(function(data) {
    // create the grid after the data is loaded
    if ($("#buildingsGrid").length > 0 ) {
      loadLocations(data);
    }
    if ($("#unitsGrid").length > 0 ) {
      loadUnits(data);
    }
  });
}
