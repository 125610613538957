// create a new controller to toggle the dropdown
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ["dropdown", "icon"]

    connect() {
    }
    
    toggle(e) {
        e.preventDefault();
        this.dropdownTarget.classList.toggle("hidden")
        this.iconTarget.classList.toggle("t-upside-down")
    }
}
