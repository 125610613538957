import consumer from "./consumer"

consumer.subscriptions.create("RouteChannel", {

  connected() {
    // console.log("connected to route channel")
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if (data["csv_upload"] && data["csv_upload"] == "complete") {
      location.reload();
    } else {
      var position = data["position"]
      var count = data["count"]
      var progress = data["progress"]
      toastr.options = {"preventDuplicates": true}
      var text;

      var text = "Analysis " + Math.round(progress) + "% Complete. " + count + " routes calculated."

      if ($(".toast").length == 0) {
        toastr.success(text)
      } else {
        var el = $(".toast")[0]
        $(el).children('.toast-message').html(text);
      }
    }
  }
});


