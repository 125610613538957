import { initGrid } from './initGrid';
import { removeCompanyLocations, removeCompanyLocationUnits } from './gridActions';

$(document).on('turbolinks:load', function () {
    const companyId = $('#gridContent').data("companyid");
    if (companyId) {
        initGrid(companyId);
    }

    $("#remove-locations").click(function () {
        const companyLocationArray = window.buildingGrid.getSelectedNodes().map(node => node.data.company_location_id);
        removeCompanyLocations(companyLocationArray, companyId);
    });
    $("#remove-units").click(function () {
        const companyLocationUnitArray = window.unitGrid.getSelectedNodes().map(node => node.data.company_location_unit_id);
        removeCompanyLocationUnits(companyLocationUnitArray, companyId);
    });
});
