import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["select", "element"];

  initialize() {
    // super.initialize()
    // this.colorValue = this.element.dataset.colorPickerColorValue
  }

  connect() {
    // this.colorValueChanged()
    let status = this.selectTarget.value.toLowerCase().replace(/\s+/g, '-');
    this.updateForm(status);
  }

  change(e) {
    
    
    let status = e.target.value.toLowerCase().replace(/\s+/g, '-');
    this.updateForm(status)
  }

  updateForm(status) {
    this.elementTargets.forEach((element) => {
      const elementValues = element.dataset.value.split(" ");
      if (elementValues.includes(status)) {
        element.classList.remove("d-none");
      } else {
        element.classList.add("d-none");
      }
    });
  }

}
