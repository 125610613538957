import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'lightroom',
    'container',
    'leftButton',
    'rightButton',
    'image',
    'toggleButton',
  ];

  
  launchLightRoom(event) {
    event.preventDefault();
    if (document.querySelector('.lightroom-modal').dataset.photoPosition !== undefined) {
      let photoPosition = document.querySelector('.lightroom-modal').dataset.photoPosition;
      this.slides = Array.from(document.querySelectorAll(".photo-slide")).sort((a, b) => {
        return photoPosition.indexOf(a.dataset.photoId) - photoPosition.indexOf(b.dataset.photoId);
      })
    } else {
      this.slides = Array.from(document.querySelectorAll('.photo-slide')).sort(
        (a, b) => {
          return (
            a.dataset.photoPosition -
            b.dataset.photoPosition
            );
          }
        );
        this.pushSlides();
    }
    // this.checkFeatured();
    this.currentSlide = parseInt(this.lightroomTarget.dataset.currentIndex);
    this.slides.forEach((slide) => {
      slide.classList.add("hidden");
    })
    this.slides[this.currentSlide].classList.remove("hidden");
    this.lightroomTarget.classList.toggle('hidden');
  }
  
  closeLightRoom(event) {
    event.preventDefault();
    this.lightroomTarget.classList.toggle('hidden');
  }
  
  nextPhoto(event) {
    event.preventDefault();
    
    if (this.currentSlide < this.slides.length - 1) {
      this.showSlide(this.currentSlide + 1)
    }
  }
  
  previousPhoto(event) {
    event.preventDefault();
    if (this.currentSlide > 0) {
      this.showSlide(this.currentSlide - 1)
    }
  }
  
  showSlide(n) {
    this.slides[this.currentSlide].classList.add("hidden");
    this.currentSlide = (n + this.slides.length) % this.slides.length;
    this.slides[this.currentSlide].classList.remove("hidden");
  }

  pushSlides() {
     let emptySlides = this.slides.filter((slide) => {
       return slide.dataset.photoPosition === '';
     });
     this.slides = this.slides.filter((slide) => {
       return slide.dataset.photoPosition !== '';
     });
     this.slides = this.slides.concat(emptySlides);
  }

  checkFeatured() {
    let featuredSlide = this.slides.filter((slide) => {
      return slide.dataset.featuredPhoto === 'featured';
    });
    this.slides = this.slides.filter((slide) => {
      return slide.dataset.featuredPhoto !== 'featured';
    });
    this.slides = featuredSlide.concat(this.slides);
  }
}
