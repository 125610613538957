import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "box", "image"];

  initialize() {
    // super.initialize()
    // this.colorValue = this.element.dataset.colorPickerColorValue
  }

  connect() {
    // this.colorValueChanged()
  }

  toggle(e) {
    e.preventDefault();
    if (this.containerTarget.classList.value.indexOf("hidden") > -1) {
      this.containerTarget.classList.remove("hidden", "border-white");
      this.boxTarget.classList.remove("border-1", "border-light-grey");
      this.boxTarget.classList.add("border-blue", "outline-2", "outline-blue");
      this.imageTarget.classList.remove("br-bl-12");
      
      // this.boxTarget.classList.add("border-blue", "ds-1-blue", "shadow-blue");
      $(".location-card-image")
    } else {
      this.boxTarget.classList.remove("border-blue", "outline-2", "outline-blue");
      this.boxTarget.classList.add("border-1", "border-light-grey");
      this.containerTarget.classList.remove("hidden");
      this.containerTarget.classList.add("hidden");
      this.imageTarget.classList.add("br-bl-12");
    }
  }

  // colorValueChanged() {
  //   this.pickerTarget.value = this.colorValue
  //   this.fieldTarget.value = this.colorValue
  // }
}
