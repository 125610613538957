import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['password', 'icon'];

  togglePassword() {
    if (this.passwordTarget.type === 'password') {
      this.passwordTarget.type = 'text';
    } else {
      this.passwordTarget.type = 'password';
    }
    this.iconTarget.children.forEach((child) => {
      child.classList.toggle('hidden');
    });
  }
}
