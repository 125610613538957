import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "box", "upicon", "downicon"];

  initialize() {
    // super.initialize()
    // this.colorValue = this.element.dataset.colorPickerColorValue
  }

  connect() {
    // this.colorValueChanged()
  }

  toggle(e) {
    e.preventDefault();
    // show/hide contents of the container
    if (this.containerTarget.classList.value.indexOf("hidden") > -1) {
      this.containerTarget.classList.remove("hidden");      
    } else {
      this.containerTarget.classList.add("hidden");
    }
    // show/hide the caret - switch up and down
    if (this.upiconTarget.classList.value.indexOf("hidden") > -1) {
        this.upiconTarget.classList.remove("hidden");
        this.downiconTarget.classList.add("hidden");      
      } else {
        this.upiconTarget.classList.add("hidden");
        this.downiconTarget.classList.remove("hidden");
      }
  }
}
