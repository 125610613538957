import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['submitButton'];

  initialize() {
    this.initialFormValues = $('#location-settings-form').serialize();
    this.url = '';
  }

  connect() {
      $('#location-settings-form :input').change(function () {
        if ($('#location-settings-form').serialize() !== this.initialFormValues) {
          $('#location-settings-form').data('changed', true);
        } else {
          $('#location-settings-form').data('changed', false);
        }
        if ($('#location-settings-form').data('changed')) {
          addEventListener('turbolinks:before-visit', this.beforeUnloadListener, {
            capture: true,
          });
        } else {
          removeEventListener('turbolinks:before-visit', this.beforeUnloadListener, {
            capture: true,
          });
        }
      }.bind(this))
  }

 beforeUnloadListener = (event) => {
    event.preventDefault();
    $(".unsaved-changes-modal").removeClass("hidden");
    this.url = event.data.url;
  };

  discardChanges(event) {
    event.preventDefault();
    $(".unsaved-changes-modal").addClass("hidden");
    $('#location-settings-form').data('changed', false);
    $('#location-settings-form').trigger('reset');
    removeEventListener('turbolinks:before-visit', this.beforeUnloadListener, {
      capture: true,
    })
    Turbolinks.visit(this.url);
  }

  saveChanges(event) {
    event.preventDefault();
    $(".unsaved-changes-modal").addClass("hidden");
    $('#location-settings-form').data('changed', false);
    removeEventListener('turbolinks:before-visit', this.beforeUnloadListener, {
      capture: true,
    })
    $('#location-settings-form-submit-button').click();
  }
}
