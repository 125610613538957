import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "select", "count", "deleteButton", "locationIds", "accountId"];

  initialize() {
    // super.initialize()
    // this.colorValue = this.element.dataset.colorPickerColorValue
    this.array = [];
  }

  connect() {
    
    console.log("connected");
  }

  submit(e) {
    console.log("submit");
    this.locationIdsTargets.forEach(target => {
      target.value = this.array;
    });
    var newCompanyModal = new bootstrap.Modal($("#newCompanyForm"));
    newCompanyModal.toggle();
  }

  delete(e) {
    console.log("delete");
    if (confirm("Are you sure you want to delete these locations?")) {
      var accountId = this.accountIdTarget.getAttribute("data-id");
      $.ajax(
        {
          url: `/accounts/${accountId}/bulk-destroy-locations`,
          data: {location_ids: this.array},
          method: "POST"
        }
      );
    }
  }

  updateButton() {
    console.log("enabledButton");
    var arrayLength = this.array.length;
    this.countTarget.innerHTML = arrayLength;
    
    if(arrayLength > 0) {
        this.buttonTarget.disabled = false;
        this.deleteButtonTarget.disabled = false;
    } else {
        this.buttonTarget.disabled = true;
        this.deleteButtonTarget.disabled = true;
    }
    
  }

  toggleAll(e) {
    
    this.array = [];

    if (e.target.checked) {
        // debugger
        this.selectTargets.forEach(target => {        
            target.checked = true;
            this.array.push(target.getAttribute("data-id"));
        });
        
    } else {
        this.selectTargets.forEach(target => {
            target.checked = false;
        });
    }    
    this.updateButton()
  }

  toggle(e) { 
    console.log("toggle");
    if (e.target.checked) {
        // debugger
        this.array.push(e.target.getAttribute("data-id"));
        console.log(this.array);
    } else {
        this.array = this.array.filter(item => item !== e.target.getAttribute("data-id"));
        console.log(this.array);
    }
    this.updateButton()
  }
}
