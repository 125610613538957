import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "box", "listSection", "mapSection", "upIcon"];

  initialize() {
    // super.initialize()
    // this.colorValue = this.element.dataset.colorPickerColorValue
  }

  connect() {
    // this.colorValueChanged()
  }

  toggle(e) {
    e.preventDefault();
    // show/hide contents of the container
    if (this.containerTarget.classList.value.indexOf("hidden") > -1) {
      this.containerTarget.classList.remove("hidden");      
    } else {
      this.containerTarget.classList.add("hidden");
    }
    // Rotate the icon
    if (this.listSectionTarget.classList.value.indexOf("d-none") > -1) {
        this.listSectionTarget.classList.remove("d-none");     
        this.mapSectionTarget.classList.add("d-none");     
      } else {
        this.listSectionTarget.classList.add("d-none");     
        this.mapSectionTarget.classList.remove("d-none");     
      }
     let test = document.getElementById("mobile-company-info-container");
     console.log(test)
    }
    
    toggleInfo(e) {
      e.stopPropagation();
      this.containerTarget.classList.toggle("hidden")
      this.upIconTarget.classList.toggle("t-upside-down");
      // toggle map height when company info box is open
      ['mobile-map-height', 'mobile-map-height-open-info'].map(c => document.getElementById('map').classList.toggle(c))
  }
}
