import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container', 'button'];

  connect() {

    this.validateForm();

    this.containerTarget.querySelectorAll('input').forEach((input) => {
      if (input.type === 'submit') return;
      input.addEventListener('keyup', (event) => {
        this.validateForm();
      });
    });
  }

  validateForm() {
    let disabled = false
    this.containerTarget.querySelectorAll('input').forEach((input) => {
      if (input.type === 'submit') return;
      if (input.value === '') {
        return disabled = true;
     }
    });

    this.buttonTarget.disabled = disabled ? true : false;
  }
}
