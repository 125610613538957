import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = ["container", "hiddenTag", "icon"];

  initialize() {
    // super.initialize()
    // this.colorValue = this.element.dataset.colorPickerColorValue
  }

  connect() {
    this.initializeTooltips();
  }

  toggle(e) {
    e.preventDefault();
    // show/hide contents of the container
    this.hiddenTagTargets.forEach(target => {
      target.classList.toggle("d-none");
    });

    this.iconTargets.forEach(target => {
      target.classList.toggle("justify-content-center"); 
      target.classList.toggle("justify-content-start"); 
    });

    this.containerTarget.classList.toggle("w-250px");
    this.containerTarget.classList.toggle("w-75px");

    if (this.containerTarget.classList.contains("w-250px")) {
      document.querySelector(".main-container").style.marginLeft = "250px";
      this.disableTooltips(); // Disable tooltips when expanded
    } else {
      document.querySelector(".main-container").style.marginLeft = "75px";
      this.enableTooltips(); // Enable tooltips when collapsed
    }
   
  }

  initializeTooltips() {
    // Enable tooltips only within the container target
    this.tooltipList = [].slice.call(this.containerTarget.querySelectorAll('[data-bs-toggle="tooltip"]'))
      .map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
      });
  }

  enableTooltips() {
    console.log("enabling tooltips")
    // Enable tooltips by showing them
    this.initializeTooltips();
  }

  disableTooltips() {
    console.log("disabling tooltips")
    
    // Disable tooltips by hiding them
    this.tooltipList.forEach(tooltip => {
      tooltip.dispose();
    });
  }
}
